<template>
  <editor-pane class="details-pane">
    <section class="header">

      <h1 v-if="!editingName">
        {{ name }}
        <button type="button" class="icon" @click="editName"><font-awesome-icon icon="edit" /></button>
      </h1>
      <form v-else @submit.prevent="saveDetails">
        <input type="text" name="documentName" v-model="name" ref="nameInput" @keydown="tryEscape"/>
        <button type="submit" class="icon"><font-awesome-icon icon="save" /></button>
      </form>

      <p class="desc" v-if="!editingDesc">
        {{ description }}
        <button type="button" class="link" @click="editDesc">edit</button>
      </p>
      <form v-else @submit.prevent="saveDetails">
        <textarea name="documentDesc" v-model="description" ref="descInput" @keydown="tryEscape"/>
        <button type="submit" class="primary"><font-awesome-icon icon="save" /> Save</button>
      </form>

    </section>

    <section class="details">
      <h2>Details</h2>
      <table>
        <tr>
          <th>Words</th>
          <td>{{ words.size }}</td>
        </tr>
        <tr>
          <th>Languages</th>
          <td>{{ languages.size }}</td>
        </tr>
      </table>
    </section>
  </editor-pane>
</template>

<script>
import EditorPane from '@/components/EditorPane.vue'

export default {
  name: 'DetailsPane',
  components: { EditorPane },
  data () {
    return {
      name: '',
      description: '',
      editingName: false,
      editingDesc: false,
    }
  },
  computed: {
    document () {
      return this.$store.state.editor.document
    },
    words () {
      return this.$store.state.editor.project?.words ?? new Map()
    },
    languages () {
      return this.$store.state.editor.project?.languages ?? new Map()
    },
  },
  methods: {
    editName () {
      this.sync()
      this.editingName = true
      window.requestAnimationFrame(() => {
        this.$refs.nameInput.focus()
      })
    },
    editDesc () {
      this.sync()
      this.editingDesc = true
      window.requestAnimationFrame(() => {
        this.$refs.descInput.focus()
      })
    },
    sync () {
      if (this.editingDesc || this.editingName) {
        this.saveDetails()
      }
      this.name = this.document.name
      this.description = this.document.description
    },
    saveDetails () {
      this.$emit('updateDocument', {
        name: this.name,
        description: this.description,
      })
      this.editingName = false
      this.editingDesc = false
    },
    tryEscape (event) {
      if (event.keyCode === 27) {
        this.name = this.document.name
        this.description = this.document.description
        this.editingName = false
        this.editingDesc = false
      }
    },
  },
  mounted () {
    this.name = this.document.name
    this.description = this.document.description
  },
}
</script>

<style lang="scss" scoped>
.details-pane {

  padding: 1rem;

  .header {
    h1 {
      font-size: 2rem;
      font-weight: 600;
    }

    .desc {
      font-style: italic;
    }

    form {
      width: 100%;

      textarea {
        width: clamp(max(200px, 90%), 50%, 90%);
      }
    }
  }

  .details {
    margin-top: 1rem;

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    table {
      margin-top: 0.5rem;
      border-collapse: collapse;

      th {
        background: var(--c-bg-shade);
      }

      th, td {
        text-align: left;
        padding: 0.5rem 0.75rem;
        border: 1px solid var(--c-border);
      }
    }
  }
}
</style>
